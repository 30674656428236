import { Backgrounds, Brand } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";
import { Colors } from "../../theme/colors";

export const Strip = () => {
    const { isMobile } = useDeviceType()
    return (
        <Section>
            <Container>
                <Row justify="space-around">
                    <Col align="start">
                        <Col justify="center" align={isMobile?"center":"start"} $maxWidth="600px" gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                            Start Your Journey Towards Better Health

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={30} $lineHeight={50} weight={700}>
                                Daily Health & Wellness
                            </Title>
                           
                          



                        </Col>

                    </Col>
                    {
                        !isMobile &&
                        <Col align="end">
                        <Img src={Brand.stripBottles}/>
                        </Col>
                    }
                    
                </Row>

            </Container>

        </Section>
    )
}



const Section = styled.div<{ $zIndex?: number, padding?: string }>`
  margin:20px 0px;
  padding:${props => props.padding || '10px 0px'};
  position: relative;
  width: 100%;
  height: 162px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden; 
  z-index: ${props => props.$zIndex !== undefined ? props.$zIndex : 0};

`;