import { useEffect } from "react";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { HomeComponent } from "../components/home";
import { CartComponent } from "../components/cart";
import { Col, Img } from "../style";
import { Images } from "../constants/images";


export const Ingredients = () => {
    return (
        <>
        <Header/>
            <Col>
                <Img src={Images.Ingredients}/>
            </Col>
            
        <Footer/>
        </>
        
    )
  
};
