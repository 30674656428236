import { Backgrounds, Images } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";

export const Wellness = () => {
    const { isMobile } = useDeviceType()
 
    return (
        <Section $isMobile={isMobile} id="about">
            <Container>
                <Row>
                    <Col align="end">
                        <Col justify="center" align="start" $maxWidth="600px" gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                                Keto For Wellness With

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={40} $lineHeight={50} weight={700}>
                            SlimmyGummy
                            </Title>
                            <Img src={Images.LineLft}/>
                            <Col $maxWidth="550px" margin="0px 0px 20px 0px" gap="20px">
                                <Text>
                                Those who follow keto with dedication are likely to shed the extra inches quite fast and easy. But, often it so happens that the typical diet rich in fat content is hard on the body without added nutritional support.
                                </Text>
                                <Text>
                                <b>SlimmyGummy</b> is a trusted wellness brand introducing easy-to-use, optimally fortified, safe and effective keto diet supplement capsule enriched with natural superfoods, which may help promote weight management plans for optimal health and wellbeing.
                                </Text>
                            </Col>
                            <Btn width="240px" to={"/#shop"}>place order</Btn>



                        </Col>

                    </Col>
                </Row>

            </Container>

        </Section>
    )
}




const Section = styled.div<{ $isMobile?: boolean}>`
  position: relative;
  width: 100%;
  height: 668px;
  padding:'10px 0px';
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Backgrounds.Wellness});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; // Ensure the background covers the entire section
    z-index: -1; // Place it behind the content
  }

  /* Optional: Add a background color with some transparency if you want */
  background-color:${props=>props.$isMobile?'rgba(255, 255, 255, 0.6)':'none'} ; // Example: white with 60% opacity: ;
`;
