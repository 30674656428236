import { useEffect } from "react";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { HomeComponent } from "../components/home";
import { CartComponent } from "../components/cart";
import { Col, Container, Img, Title, WrappedRow,Text } from "../style";
import { Icons, Images } from "../constants/images";
import styled from "styled-components";
import { Colors } from "../theme/colors";


export const Contact = () => {
    return (
        <>
        <Header/>
            <Container>
                <Title $isMobile={false} $fontSize={40} $lineHeight={50} weight={700}>Contact Us</Title>
                <WrappedRow margin="50px 0px" $gap="30px">
                    <ContactBox>
                        <Img src={Icons.Phone} width={32}/>
                        <Title $isMobile={false} $fontSize={24} $lineHeight={35} weight={600}>Phone</Title>
                        <Text>{process.env.REACT_APP_API_PHONE}</Text>
                    </ContactBox>

                    <ContactBox>
                        <Img src={Icons.Mail} width={32}/>
                        <Title $isMobile={false} $fontSize={24} $lineHeight={35} weight={600}>E-Mail</Title>
                        <Text>{process.env.REACT_APP_EMAIL}</Text>
                    </ContactBox>


                    <ContactBox>
                        <Img src={Icons.Time} width={32}/>
                        <Title $isMobile={false} $fontSize={24} $lineHeight={35} weight={600}>Operation Hours</Title>
                        <Text>Monday – Friday 09:00 – 17:00 ET</Text>
                    </ContactBox>

                </WrappedRow>
            </Container>
            
            
        <Footer/>
        </>
        
    )
  
};

const ContactBox = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 325px;
    gap: 15px;
    padding: 10px;
  overflow: hidden; 
    /* Adding the semi-circle */
    &::before {
        content: '';
        position: absolute;
        top: -30px; /* Adjust the position as needed */
        right: -30px; /* Adjust the position as needed */
        width: 80px; /* Adjust the size as needed */
        height: 80px; /* Adjust the size as needed */
        background-color: ${Colors.primary}; /* The color of the semi-circle */
        border-radius: 50%;
    }
`;
