export const routesMap = {
    homePage:{ 
        name: "Slimmy Gummy",
        to: "/",
        meta:{
            description: "SlimmyGummy - Advanced Keto Diet Supplement for optimal health and wellness. Achieve your fitness goals with our natural superfood-enriched capsules.",
            keywords: "SlimmyGummy, keto diet supplement, weight management, natural superfoods, health and wellness, ketogenic regimen, fitness"
        }
    },
    ingredients:{ 
        name: "Ingredients",
        to: "/ingredients",
        meta:{
            description: "Explore the powerful ingredients in SlimmyGummy, including Apple Cider Vinegar Powder, Pomegranate Juice Powder, Beet Juice Powder, Folate, and Vitamin B12, all formulated to support your ketogenic diet and overall wellness.",
            keywords: "SlimmyGummy ingredients, Apple Cider Vinegar, Pomegranate Juice, Beet Juice, Folate, Vitamin B12, keto supplement ingredients, wellness, weight management, ketogenic diet"
        }
    },
    contact:{ 
        name: "Contact Us",
        to: "/contact",
        meta:{
            description: "Get in touch with SlimmyGummy for any inquiries or support regarding our keto diet supplements. We're here to help you on your wellness journey.",
            keywords: "contact SlimmyGummy, customer support, keto supplement inquiries, SlimmyGummy contact information, wellness support"
        }
    },
};