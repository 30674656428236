import { Backgrounds, Images } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, WrappedRow, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";
import { Colors } from "../../theme/colors";
import { Selector } from "../generic/Selector";
import { useFormHandling } from "../../hooks/FormHandle";
import { RequestConfigs } from "../../api";
import { useEffect, useState } from "react";
import { Offers } from "../offers";

export const Products = () => {
    const { isMobile } = useDeviceType()
    const [isRecurring,setRecurring] =  useState<boolean>(true)
    const handleClick=()=>{
        console.log(isRecurring,"isRecurring")
        setRecurring(prev=>!prev)
    }
    
    
    return (
        <Section id="shop">
            <Container>
                <Row>
                    <Col align="center" gap="20px">
                        <Col justify="center" align="center"  gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                                Shop Our

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={40} $lineHeight={50} weight={700}>
                                Popular Products
                            </Title>
                            <Img src={Images.LineCtr}/>
                            <Col margin="0px 0px 0px 0px" gap="20px">
                                <Text>
                                Adhering to a healthy lifestyle that comprises the following regimen may help offer best results.
                                </Text>
                            </Col>
                            <Selector btn1Text="Auto refill subscription" btn2Text="One time purchase" onClick={handleClick}/>
                            



                        </Col>

                        <WrappedRow>
                          <Offers recurringOffers={isRecurring}/>
                          
                        </WrappedRow>

                    </Col>
                </Row>

            </Container>

        </Section>
    )
}



const Section = styled.div<{ $zIndex?: number, padding?: string }>`
  
  padding:${props => props.padding || '10px 0px'};
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  z-index: ${props => props.$zIndex !== undefined ? props.$zIndex : 0};

`;

const Box = styled.div`
display: flex;
position: relative;
border: 1px solid ${Colors.primary};
border-radius: 5px;
`