
import { HealthyLiving } from "./healthyLiving";
import { Hero } from "./hero";
import { Products } from "./products";
import { Strip } from "./strip";
import { Wellness } from "./wellness";

export const HomeComponent = () => {
    return (
        <>
        <Hero/>
        <Wellness/>
        <Strip/>
        <HealthyLiving/>
        <Products/>
        </>
        
    )
}



