import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { routesMap } from '../../constants/routesMap';
import { Brand } from '../../constants/images';



export const MetaTags: React.FC = () => {
    const location = useLocation();
    const routes = Object.values(routesMap)
    const current = routes.find(r=>r.to===location.pathname)
    const description = current?.meta?.description || ""
    const title = current?.name
    const keywords = current?.meta?.keywords || ""
    const path =  process.env.REACT_APP_SELF_URL || "" + current?.to
    

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={Brand.logo} />
        <meta property="og:url" content={path} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={Brand.logo} />
        <link rel="canonical" href={path}/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={process.env.REACT_APP_BRAND_NAME} />
      </Helmet>
    </>
    
      
    
  );
};


