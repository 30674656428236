import Joi from "joi"
import { useDeviceType } from "../../context/DeviceContext"
import { Col, Row, Text, Title, StyledHR, Img, Btn } from "../../style"
import { FieldType, TextFieldType } from "../fields"
import { FormComponent } from "../generic/Form"
import { TextLink } from "../generic/TextLink"
import states from 'states-us';
import { usePopup } from "../../context/PopUpContext"
import { useFormHandling } from "../../hooks/FormHandle"
import { RequestConfigs } from "../../api"
import { useCart } from "../../context/CartContext"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useClient } from "../../context/ClientContext"
import { Images } from "../../constants/images"

const StatesOptions = states.map(state => ({
    value: state.abbreviation,
    label: `${state.name} (${state.abbreviation})`
}));



const paymentFields:FieldConfig[] = [
      {
        type:FieldType.text,
        name:"cardNumber",
        label:"Card Number",
        joiSchema:Joi.string().creditCard().required().allow("7997285399919009").allow("7012000033330026").allow("7485666666666668").allow("7997285399919009").allow("7444333322221111").allow("7005519200000004").allow("7166676667666746"),
        typeOptions:{type:TextFieldType.creditCard}
      },
      
      
      {
        type:FieldType.text,
        name:"expiration",
        label:"expiration(MM/YY)",
        joiSchema:Joi.string().pattern(/^(0[1-9]|1[0-2])\/([0-9]{2})$/).required(),
        typeOptions:{type:TextFieldType.expiration}
      },
      {
        type:FieldType.text,
        name:"cardSecurityCode",
        label:"CVV",
        joiSchema:Joi.string().pattern(/^[0-9]+$/).length(3).required(),
        typeOptions:{type:TextFieldType.number,maxLength:3}
      },
      
      
]
const billingFields:FieldConfig[] = [
    {
        type: FieldType.text,
        name: "firstName",
        label: "First Name",
        joiSchema: Joi.string().max(50).required(),
        typeOptions: {
            type: TextFieldType.text
        }

    },

    {
        type: FieldType.text,
        name: "lastName",
        label: "Last Name",
        joiSchema: Joi.string().max(50).required(),
        typeOptions: {
            type: TextFieldType.text
        }

    },
    {
        type: FieldType.text,
        name: "emailAddress",
        label: "Email",
        joiSchema: Joi.string().email({ tlds: { allow: false } }).required(),
        typeOptions: {
            type: TextFieldType.text
        }

    },
    {
        type: FieldType.phone,
        name: "phoneNumber",
        label: "Phone",
        joiSchema: Joi.string().pattern(/^[0-9-]+$/).required(),
        typeOptions: {
            countryFieldName:"country"
        },

    },
    {
        type: FieldType.select,
        name: "state",
        label: "State",
        joiSchema: Joi.string().max(30),
        typeOptions: {
            options: StatesOptions
        }
    },
    {
        type: FieldType.text,
        name: "city",
        label: "City",
        joiSchema: Joi.string().max(30).required(),
        typeOptions: {
            type: TextFieldType.text
        }
    },

    {
        type: FieldType.text,
        name: "address1",
        label: "Address",
        joiSchema: Joi.string().max(50).required(),
        typeOptions: {
            type: TextFieldType.text
        }

    },


    {
        type: FieldType.text,
        name: "postalCode",
        label: "Zip code",
        joiSchema: Joi.string().max(20).required(),
        typeOptions: {
            type: TextFieldType.text
        }

    },
]

export const Billing = () => {
    const { isMobile } = useDeviceType()
    const {openPopup} = usePopup()
    const {cart, emptyCart,coupon} = useCart()
    const navigate = useNavigate()
    const {client} = useClient()
    const [combinedServerRes, setCombinedServerRes] = useState<ApiResponse | null>(null);
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');
    const errorMsg = queryParams.get('errorMsg');
  

    const termsField = {
        type:FieldType.checkBox,
        name:"allowTerms",
        label:"terms",
        joiSchema:Joi.boolean().valid(true).required(), 
        typeOptions:{text:(<Text $lineHeight="16px" $fontSize="12px">I agree to <TextLink hasLine={true} fontSize="12px"  onClick={()=>openPopup(`/${process.env.REACT_APP_MARKET}/privacy`)}>privacy policy</TextLink> and <TextLink onClick={()=>openPopup(`/${process.env.REACT_APP_MARKET}/terms`)} route={"#"} hasLine={true} fontSize="12px">terms of conditions</TextLink></Text>)}
    }

    if(paymentFields.findIndex(f=>f.name==="allowTerms")===-1){
        paymentFields.push(termsField)
    }

    const {serverRes,handleClick,handleInputChange,submitted,formValues} = useFormHandling({
        requestType:RequestConfigs.createOrder,
        data:{
            products:cart,
            returnUrl:window.location.origin + window.location.pathname,
            campaignId:process.env.REACT_APP_CAMPAIGN_ID,
            billShipSame:true,
            clearSessionOrder:true,
            couponCode:coupon?.couponCode || null

        }
    }
    )

    billingFields[4].defaultValue = client?.country === "US" ? client?.state : null
    billingFields[3].typeOptions.defaultCountry = client?.country?.toLowerCase()
    billingFields[4].hide = client?.country === "US" ? false : true

    if (formValues?.country) {

        if (formValues?.country?.toUpperCase() !== "US") {
            billingFields[4].hide = true
        } else {
            billingFields[4].hide = false
        }


    } else {
        if (client?.country === "US") {
            billingFields[4].hide = false
        } else {
            billingFields[4].hide = true
        }

    }

    useEffect(() => {

        const combined:ApiResponse | null = serverRes?serverRes:(success?{
          message:success==="true"?"OK":errorMsg || "failed",
          succeeded:success==="true",
        }:null)
        if (JSON.stringify(combined) !== JSON.stringify(combinedServerRes)) {
            setCombinedServerRes(combined);
          }
        
      }, [serverRes,queryParams]);


      useEffect(() => {
        if (combinedServerRes?.succeeded) {
          if (combinedServerRes?.redirect) {
            window.location.href = combinedServerRes?.redirect;
          } else {
            emptyCart()
            // Get the current pathname
            const currentPath = window.location.pathname;
      
            // Split the path into segments
            const pathSegments = currentPath.split('/');
      
            // Remove the last segment (which could be 'checkout' or anything else)
            pathSegments.pop();
      
            // Join the remaining segments and add '/thank-you'
            const thankYouPath = pathSegments.join('/') + '/thank-you';
      
            // Navigate to the new path
            navigate(thankYouPath);
          }
        }
      }, [combinedServerRes]);




    return (
        <>
            <Col margin="10px 50px">
                <Row>
                    <Col align="start">
                        <Title $isMobile={isMobile} $fontSize={26} $lineHeight={26} weight={600}>Shipping Information</Title>
                    </Col>
                    
                </Row>
                <StyledHR />
                <FormComponent fields={billingFields} submitted={submitted} onChange={handleInputChange} serverRes={serverRes} showMessageBox={false}/>

                
            </Col>
            <Col margin="10px 50px">
                <Row>
                    <Col align="start">
                        <Title $isMobile={isMobile} $fontSize={26} $lineHeight={26} weight={600}>Payment Information</Title>
                        
                    </Col>
                    
                </Row>
                <StyledHR />
                        <Col align="start">
                            
                            <Row justify="flex-start">
                                <Text weight={600} margin="20px 25px 0px 0px">We Accept:</Text>
                                <Img width={"65px"} src={Images.Visa}/>
                                <Img width={"65px"} src={Images.Mastercard}/>
                                { process.env.REACT_APP_MARKET==="us" &&
                                    <Img width={"60px"} src={Images.Amex}/>
                                }
                                
                            </Row>
                        </Col>
                <FormComponent fields={paymentFields} submitted={submitted} onChange={handleInputChange} serverRes={combinedServerRes}/>
                <Btn to={"#"} onClick={handleClick}>Complete Checkout</Btn>

                
            </Col>

            



        </>
    )
}