import { Backgrounds } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";
import { Colors } from "../../theme/colors";

export const Hero = () => {
    const { isMobile } = useDeviceType()
    return (
        <Section $isMobile={isMobile}>
            <Container>
                <Row>
                    <Col align="start">
                        <Col justify="center" align="start" $maxWidth="600px" gap="10px">
                            <Title $isMobile={isMobile} $fontSize={17} $lineHeight={30}>
                                ADVANCED DIETARY SUPPLEMENT

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={54} $lineHeight={64}>
                                Optimizing The Ketogenic Regimen
                            </Title>
                            <Col $maxWidth="350px" margin="0px 0px 20px 0px">
                                <Text weight={500} color={Colors.blackText}>
                                    Experience optimal health and fitness with the nourishing potion of all-advanced and optimally nourishing Keto Diet Supplement.
                                </Text>
                            </Col>
                            <Btn width="240px" to={"/#shop"}>place order</Btn>



                        </Col>

                    </Col>
                </Row>

            </Container>

        </Section>
    )
}



const Section = styled.div<{ $isMobile?: boolean}>`
  position: relative;
  width: 100%;
  height: 668px;
  padding:'10px 0px';
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Backgrounds.HeroBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; // Ensure the background covers the entire section
    z-index: -1; // Place it behind the content
  }

  /* Optional: Add a background color with some transparency if you want */
  background-color:${props=>props.$isMobile?'rgba(255, 255, 255, 0.6)':'none'} ; // Example: white with 60% opacity: ;
`;
