
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../theme/colors';
import { useState } from "react";

interface SwitcherProps{
    btn1Text:string;
    btn2Text:string;
    onClick:(btn:number)=>void
}

// Component
export const Selector = ({btn1Text,btn2Text,onClick}:SwitcherProps) => {
  
  const [activeButton,setActiveButton] = useState<number>(0)
 
  const handleClick = (button:number) => {
    console.log(button,"bbb")
    setActiveButton(button)
    onClick(button)
    
    }

  

  return (
    <SwitchContainer>
      
      {
      activeButton===0 &&
      <>
      <ActiveButton>{btn1Text}</ActiveButton>
      <Button onClick={() => handleClick(1)}>{btn2Text}</Button> 
      </>
      }

{
      activeButton===1 &&
      <>
      <Button onClick={() => handleClick(0)}>{btn1Text}</Button> 
      <ActiveButton>{btn2Text}</ActiveButton>
      </>
      }
     
    </SwitchContainer>
  );
}

const Button = styled.button`
  flex: 1;
  height: 44px;
  border: none;
  background-color: transparent;
  color: ${Colors.blackText};
  border-radius: 15px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  cursor:pointer ;
  &:focus {
    outline: none;
  }
`;


const ActiveButton = styled(Button)`
  background-color: ${Colors.primary};
  color: ${Colors.whiteBackground};
`;

const SwitchContainer = styled.div`
  /* margin: 20px 20px; */
  display: flex;
  width: 400px;
  border-radius: 20px;
  border: 2px solid ${Colors.blackText};
  background-color: ${Colors.whiteBackground};
  position: relative;  
  justify-content: center;  
  cursor:pointer ;
`;





