import { useCart } from "../../context/CartContext"
import { useDeviceType } from "../../context/DeviceContext"
import { Container, WrappedRow,Col, Section, Title,Text, Btn } from "../../style"
import { Billing } from "./billing"
import { CartProducts } from "./cartProducts"



export const CartComponent = () => {

    const {cart} = useCart()
    const {isMobile} = useDeviceType()
    return (
        <>
        <Section $backgroundColor1="white">
        <Container>
            
                { cart.length>0 && 
                <WrappedRow justify="space-between">
                    <Col $maxWidth="600px" justify="flex-start">
                    <CartProducts/>
                </Col>
                <Col $maxWidth="450px" justify="flex-start">
                    <Billing/>
                </Col>
                </WrappedRow>
                }

                { (!cart || cart.length===0) && 
                    <Col gap="20px" margin="50px 0px 400px 0px">
                        <Text>You have not selected any product...</Text>
                        <Btn to="/">Browse Products</Btn>
                    </Col>
                }               
                
            
        </Container>

        </Section>
        
        </>
        
    )
}



