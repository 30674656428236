import styled from "styled-components"
import { Colors } from "../../theme/colors"
import {  Title,Row, Btn } from "../../style"
import { useDeviceType } from "../../context/DeviceContext"
import { useCart } from "../../context/CartContext"

interface ProductBoxProps {
    campaign:Campaign
    productLocation:number
       
}

export const ProductBox = ({campaign,productLocation}:ProductBoxProps)=>{

    const {addToCart} = useCart()

    const removeLeadingNumbers=(str:string)=> {
        return str.replace(/^\(\d+\)\s*/, '');
    }
    const {isMobile} = useDeviceType()
    const product:CampaignProduct = campaign.products[productLocation]
    const isRecurring = product.billingCycleType==='RECURRING'
    const title = isRecurring?`Get ${product.productQty} Bottles every ${product.cycle1_billDelay} days`:""
    const oneButtlePromo = `Same as ${campaign.currencySymbol}${(product.price/product.productQty).toFixed(2)}/Bottle`
    const buttonText = `${campaign.currencySymbol}${product.price}` + (isRecurring ? ` / ${product.cycle1_billDelay} days`:'')
    

    return (
        <>
            <Box>
                <Title $isMobile={isMobile} $fontSize={isMobile?17:13} weight={700}>{title}</Title>
                <Row>
                    <Img src={product.imageUrl}/>
                </Row>
                <Title $isMobile={isMobile} $fontSize={16} weight={700}>{removeLeadingNumbers(product.productName)}</Title>
                <Title $isMobile={isMobile} $fontSize={19} weight={700}>{oneButtlePromo}</Title>
                <Btn $fontSize={18} onClick={()=>addToCart(product.campaignProductId)} to="/cart">{buttonText}</Btn>
                
            </Box>
        </>
    )

}

const Box = styled.div`
display: flex;
flex-direction: column;
background: ${Colors.lightPrimary};
border: 1px solid ${Colors.primary};
padding: 10px;
gap: 15px;
max-width: 300px;
min-width: 250px;
`

const Img = styled.img<{margin?:string}>`
  height: 220px;
  margin:${props => props.margin || '0px'};
`;