import { Backgrounds, Images } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, WrappedRow, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";
import { Colors } from "../../theme/colors";

export const HealthyLiving = () => {
    const { isMobile } = useDeviceType()
    return (
        <Section>
            <Container>
                <Row>
                    <Col align="center">
                        <Col justify="center" align="center"  gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                            A Guide For

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={40} $lineHeight={50} weight={700}>
                            Healthy Living
                            </Title>
                            <Img src={Images.LineCtr}/>
                            <Col margin="0px 0px 20px 0px" gap="20px">
                                <Text>
                                Adhering to a healthy lifestyle that comprises the following regimen may help offer best results.
                                </Text>
                            </Col>
                            



                        </Col>

                        <WrappedRow>
                            <Col $maxWidth="370px">
                                    <Img src={Images.HealthImg1} width={360}/>
                                    <Row justify="flex-start">
                                        <Col align="start" $maxWidth="70px" margin="-110px 0px 0px 0px">
                                           <Img src={Images.HealthImgIcon1} width={60}/>
                                        </Col>
                                        <Col padding="30px 0px" align="start" $maxWidth="270px">
                                            <Title $isMobile={isMobile} $fontSize={24} $lineHeight={36} weight={700}>
                                                Eating Habits
                                            </Title>
                                            <Text>
                                                Daily supplementation & a diet rich in fat content that includes both macro & micro nutrients.
                                            </Text>
        
                                        
                                        </Col>
                                    
                                    </Row>
                                    
                            </Col>

                            <Col $maxWidth="370px">
                                    <Img src={Images.HealthImg2} width={360}/>
                                    <Row justify="flex-start">
                                        <Col align="start" $maxWidth="70px" margin="-110px 0px 0px 0px">
                                           <Img src={Images.HealthImgIcon2} width={60}/>
                                        </Col>
                                        <Col padding="30px 0px" align="start" $maxWidth="270px">
                                            <Title $isMobile={isMobile} $fontSize={24} $lineHeight={36} weight={700}>
                                                Fitness Regimen
                                            </Title>
                                            <Text>
                                                A dynamic workout routine that includes yoga, aerobics & strength training.
                                            </Text>
        
                                        
                                        </Col>
                                    
                                    </Row>
                                    
                            </Col>

                            <Col $maxWidth="370px">
                                    <Img src={Images.HealthImg3} width={360}/>
                                    <Row justify="flex-start">
                                        <Col align="start" $maxWidth="70px" margin="-110px 0px 0px 0px">
                                           <Img src={Images.HealthImgIcon3} width={60}/>
                                        </Col>
                                        <Col padding="30px 0px" align="start" $maxWidth="270px">
                                            <Title $isMobile={isMobile} $fontSize={24} $lineHeight={36} weight={700}>
                                                Hydration & Rest
                                            </Title>
                                            <Text>
                                                Sufficient water consumption at regular intervals & a restful sleep for an optimum duration.
                                            </Text>
        
                                        
                                        </Col>
                                    
                                    </Row>
                                    
                            </Col>
                          
                        </WrappedRow>

                    </Col>
                </Row>

            </Container>

        </Section>
    )
}



const Section = styled.div<{ $zIndex?: number, padding?: string }>`
  
  padding:${props => props.padding || '10px 0px'};
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  z-index: ${props => props.$zIndex !== undefined ? props.$zIndex : 0};

`;

const Box = styled.div`
display: flex;
position: relative;
border: 1px solid ${Colors.primary};
border-radius: 5px;
`