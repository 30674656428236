import { useEffect, useState } from "react";
import { useFormHandling } from "../../hooks/FormHandle";
import { RequestConfigs } from "../../api";
import { ProductBox } from "./ProductBox";
import { useCampaign } from "../../context/CampaignContext";
import { useClient } from "../../context/ClientContext";

interface OffersProps{
    recurringOffers:boolean
}

export const Offers = ({recurringOffers}:OffersProps)=>{
    const {serverRes,handleClick} = useFormHandling({requestType:RequestConfigs.getCampaign,data:{campaignId:process.env.REACT_APP_CAMPAIGN_ID}})
    const {campaign,setCampaign} = useCampaign()
    const {setClient} = useClient()
    
    useEffect(() => {
        handleClick()
      }, []);

    useEffect(() => {
        if(serverRes && serverRes.campaign && serverRes.client){
            console.log(serverRes)
            serverRes.campaign.products.sort((a, b) => a.price - b.price)
            setCampaign(serverRes.campaign)
            setClient(serverRes.client)
        }
      }, [serverRes]);
      return(
        <>
        {campaign && campaign.products.map((product, index) => (
           recurringOffers===( product.billingCycleType === 'RECURRING') && (
                <ProductBox 
                    key={product.campaignProductId} 
                    campaign={campaign} 
                    productLocation={index} 
                />
            )
        ))}
    </>
    )

}