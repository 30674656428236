import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";

interface TextLinkProps {
  children: React.ReactNode;
  route?: string;
  onClick?:()=>void;
  fontSize?: string;
  fontColor?: string;
  fontWeight?: number;
  hasLine?: boolean;
  
}

export const TextLink: React.FC<TextLinkProps> = ({
  route="#",
  onClick=()=>{},
  children,
  fontSize,
  fontColor,
  hasLine,
  fontWeight
}: TextLinkProps) => { 
  const navigate = useNavigate()
  
  
  const handleClick = () => {
    onClick()
    navigate(route) ; // Navigate to the specified route
  };

  return (
    <>
      <Text onClick={handleClick} fontSize={fontSize} $hasLine={hasLine} $fontColor={fontColor} $fontWeigt={fontWeight}>
        {children}
      </Text>
    </>
  );
};

const Text = styled.a<{ fontSize?: string; $hasLine?: boolean; $fontColor?:string; $fontWeigt?:number }>`
  font-size: ${(props) => props.fontSize || "14px"};
  color:${props=>props.$fontColor || Colors.primary};
  font-weight: ${(props) => props.$fontWeigt || 500};
  border-bottom: ${(props) =>
    props.$hasLine ? "1px solid " + Colors.primary : "none"};
  cursor: pointer;
  &:hover {
    color: ${Colors.primary};
    /* text-decoration: underline */

  }
  
  /* padding-bottom: 2px; */
`;
