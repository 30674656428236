import bottleImage from "../assets/brand/bottle.png"
import logo from "../assets/brand/logo.png"
import stripBottles from "../assets/brand/strip_bottles.png"
import Exclamation from "../assets/icons/exclamation.svg"
import Success from "../assets/icons/success.svg"
import Eye from "../assets/icons/eye.svg"
import Error from "../assets/icons/error.svg"
import Search from "../assets/icons/search.svg"
import CartRemove from "../assets/icons/cart-remove1.png"
import Cart from "../assets/icons/cart-ic.png"
import Mail from "../assets/icons/mail.jpg"
import Phone from "../assets/icons/phone.jpg"
import Time from "../assets/icons/time.png"
import GreenV from "../assets/icons/green-v.png"
import HeroBackground from "../assets/backgrounds/banner.jpg"
import Wellness from "../assets/backgrounds/wellness.png"
import LineLft from "../assets/images/hd-line-lft.png"
import LineCtr from "../assets/images/hd-line-ctr.png"
import HealthImg1 from "../assets/images/s3-img1.png"
import HealthImg2 from "../assets/images/s3-img2.png"
import HealthImg3 from "../assets/images/s3-img3.png"
import HealthImgIcon1 from "../assets/images/s3-lst1.png"
import HealthImgIcon2 from "../assets/images/s3-lst2.png"
import HealthImgIcon3 from "../assets/images/s3-lst3.png"
import Ingredients from "../assets/images/ingredients.png"
import Visa from "../assets/images/visa.png"
import Mastercard from "../assets/images/mastercard.png"
import Amex from "../assets/images/amex.png"



export const Brand = {
    bottleImage,
    logo,
    stripBottles
}

export const Images = {
    LineLft,
    LineCtr,
    HealthImg1,
    HealthImg2,
    HealthImg3,
    HealthImgIcon1,
    HealthImgIcon2,
    HealthImgIcon3,
    Ingredients,
    Visa,
    Mastercard,
    Amex
}

export const Backgrounds = {
    HeroBackground,
    Wellness
}

export const Icons = {
    Exclamation,
    Success,
    Eye,
    Error,
    Search,
    Cart,
    CartRemove,
    Mail,
    Phone,
    Time,
    GreenV
}