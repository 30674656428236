

import styled from "styled-components";
import GlobalLoader from "./Loader";
import { useEffect, useState } from "react";
import { Notification } from "../generic/Notification";
import { TextLink } from "../generic/TextLink";
import Popup from "../generic/Popup";
import { Col,Img, Text,Container, Title, Section } from "../../style";
import { Brand } from "../../constants/images";
import { useDeviceType } from "../../context/DeviceContext";
import { LinksList, LinksListItem } from "../generic/LinksList";
import { Colors } from "../../theme/colors";
import { usePopup } from "../../context/PopUpContext";

export const Footer = () =>{
  
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [popupPath, setPopupPath] = useState<string>();
  const {isMobile} = useDeviceType()
  const {openPopup,closePopup,popIsOpen,popupPath} = usePopup()
  
  // const openPopup = (path:string) => {
  //   setPopupPath(path)
  //   setIsPopupOpen(true);
  // }
  // const closePopup = () => setIsPopupOpen(false);


  const handleHref = (path:string) => {
    window.location.href = path; // Redirect manually if needed
  };


  const contactLinks:LinksListItem[] = [
    {
      text:process.env.REACT_APP_API_PHONE || "",
      onClick:()=>handleHref(`tel:${process.env.REACT_APP_API_PHONE}`)
    },
    {
      text:process.env.REACT_APP_EMAIL || "",
      onClick:()=>handleHref(`mailto:${process.env.REACT_APP_EMAIL}`)
    },

  ]

  const termsLinks:LinksListItem[] = [
    {
      text:"Terms & Conditions",
      onClick:()=>openPopup(`/${process.env.REACT_APP_MARKET}/terms`)
    },
    {
      text:"Privacy Policy",
      onClick:()=>openPopup(`/${process.env.REACT_APP_MARKET}/privacy`)
    },

  ]

  return (
    <>
    <StyledHR/>
   
        <Section $backgroundColor1={Colors.whiteBackground}>
        <Popup
        isOpen={popIsOpen}
        contentUrl={popupPath || ""}
        onClose={closePopup}
        />
        <Container>
        <GlobalLoader/>
        <Col gap="10px">
          <Img src={Brand.logo} width={350}/>
          <Text direction="center" $fontSize="14px">* All statements and results presented on this website are for informational purposes only and have not been evaluated by the Food and Drug Administration. They are not specific medical advice for any individual. Neither the website, nor product should substitute medical advice given by a certified health professional. If you have a health problem, or you have sensible allergies, are pregnant or diagnosed with chronic conditions, it is strongly recommended that you consult your doctor immediately and before taking any pills or supplements. Individual results may vary depending on the case. The website's content and the product for sale is based upon the author's opinion and is provided solely on an "AS IS" and "AS AVAILABLE" basis. You should do your own research and confirm the information with other sources when searching for information regarding health issues and always review the information carefully with your professional health care provider before using any of the protocols presented on this website and/or in the product sold here. Neither SlimmyGummy nor the operator of SlimmyGummy.co are engaged in rendering medical or similar professional services or advice via this website or in the product, and the information provided is not intended to replace medical advice offered by a physician or other licensed healthcare provider. By purchasing products and/or services on this website you confirm giving your consent to Terms & Conditions and Privacy policy.</Text>
          { process.env.REACT_APP_MARKET==="eu" && 
            <Text>The web site is operated by QWgroup LTD. Hristina Morfova 7, Sofia, Bulgaria</Text>
          }
          
          <Title $isMobile={isMobile} $fontSize={20} $lineHeight={30} weight={500}>Connect With Us</Title>
          <LinksList links={contactLinks} fontColor={Colors.blackText} fontSize="18px" fontWeight={400}/>
          <StyledHR/>
          <LinksList links={termsLinks} fontColor={Colors.blackText} fontSize="18px" fontWeight={400}/>
        </Col>
      </Container>

        </Section>
      
    </>
  
  );
} 



const P = styled.p`
   font-size: .75rem;
    text-align: center;
    line-height: 1.4;
    margin: 0;
    /* margin-bottom: 1.25rem; */


`






const StyledHR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #cccccc;
  border: none;
  margin:0;
  z-index: 5;
  max-width: 1120;
`;

