import React, { useEffect } from "react"
import { Icons } from "../../constants/images"
import { useCart } from "../../context/CartContext"
import { useDeviceType } from "../../context/DeviceContext"
import { Col, Row, Text, Title, StyledHR, Img, Btn } from "../../style"
import { Colors } from "../../theme/colors"
import { TextLink } from "../generic/TextLink"
import { CartItem } from "./cartItem"
import { useCampaign } from "../../context/CampaignContext"
import { Field, FieldType, TextFieldType } from "../fields"
import Joi from "joi"
import { useFormHandling } from "../../hooks/FormHandle"
import { RequestConfigs } from "../../api"

const couponField = {
    type: FieldType.text,
    name: "couponCode",
    label: "Coupon Code",
    joiSchema: Joi.string().max(50).required(),
    typeOptions: {
        type: TextFieldType.text
    }

}


export const CartProducts = () => {
    const { isMobile } = useDeviceType()
    const {cart,emptyCart,coupon,setCoupon} = useCart()
    const {campaign} = useCampaign()
    const couponDiscount = coupon?.discountPerc?1-coupon.discountPerc:1

    const {serverRes,handleClick,handleInputChange,submitted} = useFormHandling({requestType:RequestConfigs.checkCoupon,data:{campaignId:campaign?.campaignId}})
 
    const toCappital = (name: string, value: any, valid: boolean)=>{
        handleInputChange(name,value.toUpperCase(),valid)
    }

    const getSubTotal = ()=>{
        let subTotal = 0
        for(let i in cart){
            const product = campaign?.products.find(pr=>pr.campaignProductId===cart[i].campaignProductId)
            subTotal+=couponDiscount*cart[i].qty* (product?.price || 0)

        }
        return subTotal.toFixed(2)

    }
    useEffect(() => {
        if(serverRes && serverRes.succeeded && (serverRes.coupon || serverRes.coupon===null)){
            setCoupon(serverRes.coupon)
        }
       
      }, [serverRes]);
    return (
        <>
            <Col margin="10px 50px">
                <Row>
                    <Col $maxWidth="60%" align="start">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>Products</Title>
                    </Col>
                    <Col $maxWidth="20%" align="center">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>QTY</Title>
                    </Col>
                    <Col $maxWidth="20%" align="center">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>Total</Title>
                    </Col>

                </Row>
                <StyledHR />
                {cart.map((cartItem, index) => (
                <React.Fragment key={index}>
                    <CartItem cartItem={cartItem}/>
                </React.Fragment>
                ))}
              
                
            </Col>

            <Col margin="10px 50px" gap="10px">
                <Row>
                    <Col $maxWidth="100%" align="start">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>Order Summary</Title>
                    </Col>
                </Row>
                <StyledHR />

                <Row justify="space-around">
                    <Col $maxWidth="100%" align="start">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={400}>Sub Total:</Title>
                    </Col>
                    <Col $maxWidth="100%" align="end">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>{campaign?.currencySymbol}{getSubTotal()}</Title>
                    </Col>
                </Row>

                <Row justify="space-around">
                    <Col $maxWidth="100%" align="start">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={400}>Shipping & Handling:</Title>
                    </Col>
                    <Col $maxWidth="100%" align="end">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>{campaign?.currencySymbol}0.00</Title>
                    </Col>
                </Row>
                <StyledHR $height={1}/>
                <Row justify="space-around">
                    <Col $maxWidth="100%" align="start">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>Total:</Title>
                    </Col>
                    <Col $maxWidth="100%" align="end">
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>{campaign?.currencySymbol}{getSubTotal()}</Title>
                    </Col>
                </Row>
                <StyledHR $height={1}/>

                <Row justify="space-between">
                    
                    <Col $maxWidth="60%" align="start">
                        <Field fieldConfig={couponField} onChange={toCappital} submitted={submitted} serverRes={serverRes}/>
                    </Col>
                    <Col align="end" $maxWidth="20%">
                        <Btn to={"#"} onClick={handleClick}>Apply</Btn>
                    </Col>
                
                </Row>
                <StyledHR />

                <Row justify="space-around">
                    <Col $maxWidth="100%" align="center">
                    <TextLink fontSize={isMobile?"16px":"20px"} hasLine={true} fontColor={Colors.blackText} route="/">Continue Shopping</TextLink>
                     
                    </Col>
                    <Col $maxWidth="100%" align="center">
                        <TextLink fontSize={isMobile?"16px":"20px"} hasLine={true} fontColor={Colors.blackText} onClick={emptyCart}>Empty Cart</TextLink>
                    </Col>
                </Row>
            </Col>




        </>
    )
}