import { PrivacyEu } from "../components/legal/eu/privacy"
import { PrivacyUs } from "../components/legal/us/privacy"


export const PrivacyPage = () =>{

    const Page = process.env.REACT_APP_MARKET==='us'?PrivacyUs:PrivacyEu
    return (
        <>
        <Page/>
        </>
        
    )
    
}