import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the types for the popup context
interface PopupContextType {
    popIsOpen: boolean;
    popupPath:string
    openPopup:(path:string)=>void;
    closePopup:()=>void;
}

// Create a context for the popup state
const PopupContext = createContext<PopupContextType | undefined>(undefined);

export function usePopup(): PopupContextType {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }
    return context;
}

interface PopupProviderProps {
    children: ReactNode;
}

export const PopupProvider = ({ children }: PopupProviderProps) => {
    const [popIsOpen, setPopIsOpen] = useState(false);
    const [popupPath, setPopupPath] = useState<string>("");

    const openPopup = (path:string)=>{
        setPopupPath(path)
        setPopIsOpen(true)
    }

    const closePopup = ()=>{
        setPopIsOpen(false)
    }

    // The value that will be provided to any descendant components
    const value: PopupContextType = { popIsOpen, popupPath,openPopup,closePopup };

    return (
        <PopupContext.Provider value={value}>
            {children}
        </PopupContext.Provider>
    );
};
