import { TermsEu } from "../components/legal/eu/terms"
import { TermsUs } from "../components/legal/us/terms"

export const TermsPage = () =>{

    const Page = process.env.REACT_APP_MARKET==='us'?TermsUs:TermsEu
    return (
        <>
        <Page/>
        </>
        
    )
    
}