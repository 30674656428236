import { useEffect } from "react";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { HomeComponent } from "../components/home";
import { useLocation } from "react-router-dom";


export const HomePage = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
    return (
        <>
        <Header/>
        <HomeComponent/>
        <Footer/>
        </>
        
    )
  
};
